import React from 'react';

const Input = ({name, label, placeholder, required = false}) => {
  return (
    <label className="block mb-8">
      <div className="flex">
        <strong className="flex-1 inline-block font-bold mb-2">{label}</strong>
        
        {!required &&
          <span className="inline-block text-gray-500 italic">optional</span>
        }
        
      </div> 
      <div className="flex">
        <div className="bg-primary w-1 h-auto rounded-tl-md"/>
        <input 
          type="text" 
          name={name} 
          id={name} 
          required
          placeholder={placeholder}
          className="block bg-white w-full py-2 px-2 outline-none border-hairline shadow-md" 
        />
      </div>
    </label>

  );
};

export default Input;