import React from 'react';

const Checkbox = ({name, option, checked}) => {
  return (
    <div className="mb-2">
      <label class="inline-flex items-center">
        <input 
          name={name}
          id={name}
          type="checkbox" 
          className="form-checkbox text-primary h-6 w-6 focus:outline-none shadow-md" 
          checked={checked} />
        <span className="ml-4">{option}</span>
      </label>
    </div>
  );
};

export default Checkbox;