import React from "react"
import Layout from "../templates/Layout"
import { Link } from "gatsby"
import Input from "../components/Input"
import Checkbox from "../components/Checkbox"
import TextArea from "../components/TextArea"
import SelectBox from "../components/SelectBox"
import Button from "../components/button"
import { graphql } from "gatsby"

const _companySize = [
  "More than 10 employees",
  "More than 50 employees",
  "More than 100 employees",
  "More than 500 employees",
  "Less than 10 employees"
]

const _companyDeadline = [
  "I need this now!",
  "8 to 6 weeks",
  "12 to 8 weeks",
  "24 to 12 weeks",
  "Not sure"
]

const ContactPage = ({ data }) => {
  return (
    <Layout page={data.prismicContact}>
      <>
        <main className="w-full lg:p-8">
          <section className="container pt-32 lg:pt-0 relative lg:grid grid-cols-2 gap-12">
            <div className="lg:col-start-2">
              <form name="contact" method="post" data-netlify="true">
                <input type="hidden" name="bot-field" data-netlify-honeypot="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <section className="lg:bg-gray-100 lg:p-8 lg:shadow-lg mb-8">
                  <Input name="name" label="Your name" required />
                  <Input name="email" label="Your Email" required />
                </section>
                <section className="lg:p-8 mb-16 border-b-hairline">
                  <Input name="phone" label="Your phone number" />
                </section>
                <section className="lg:p-8 mb-16 border-b-hairline">
                  <div className="mb-8">
                    <strong className="flex-1 inline-block font-bold mb-2">Already have a website?</strong>
                    <p>You may already have a website and are looking to refresh the design or add a custom feature.</p>
                  </div>
                  <Input name="current-website" label="Your current website" />
                </section>
                <section className="lg:p-8">
                  <div className="mb-8">
                    <strong className="flex-1 inline-block font-bold mb-2">We can build anything?</strong>
                    <p>If you have something unique in mind, we're confident that we can build it.
                      We create solutions that you may not even know is possible. 
                      <br /><br />
                      We want to hear about your challenges and we want to help you come up with solutions that will deliver results. </p>
                  </div>
                  <div className="flex mb-8">
                    <strong className="flex-1 inline-block font-bold mb-2">What kind of website are you interested in?</strong>
                    <span className="inline-block text-gray-500 italic">optional</span>
                  </div>
                  <div className="mb-8">
                    <Checkbox name="blogs-articles" option="Blog &amp; articles" />
                    <Checkbox name="brochure" option="Brochure" />
                    <Checkbox name="e-commerce" option="E-commerce" />
                    <Checkbox name="bookings-reservations" option="Bookings &amp; reservations" />
                    <Checkbox name="hybrid" option="Hybrid" />
                  </div>
                </section>
                <section className="lg:p-8 mb-12 border-b-hairline">
                  <TextArea 
                      name="special"
                      label="Do you have something special in mind?" 
                      placeholder="Tell us about a specific problem you would like us to solve?"
                    />
                    <SelectBox 
                      name="deadline"
                      label="Whats is your timeline?" 
                      options={_companyDeadline}
                      placeholder="When do you need the work done by?"
                    />
                </section>
                <section className="lg:p-8 mb-12 border-b-hairline">
                  <SelectBox 
                    name="business-size"
                    label="Size of your business" 
                    options={_companySize}
                    placeholder="How many employees do you have?"
                  />
                  <TextArea 
                    name="business-type"
                    label="What kind of business or organisation are you?" 
                    placeholder="In a few words, tell us about you're business or organisation?"
                  />
                  <Checkbox name="marketing-team" option="Do you have a marketing team?" />
                </section>
                <section className="lg:p-8 mb-0">
                  <TextArea 
                    name="additional"
                    label="Is there anything else you would like to tell us?" 
                    placeholder="Please tell us if you would like to add anything?"
                    />
                </section>
                <section className="lg:px-8 mb-4">
                  <p>This form collects your contact information so that we can correspond with you. 
                    Check out our <Link className="text-blue-400 hover:underline" to="/privacy">privacy policy&nbsp;</Link> 
                    for more information about how we protect and manage your data.
                  </p>
                </section>
                <section className="lg:px-8 mb-24">
                  <Button type="submit" text="Send" />
                </section>
              </form>
            </div>
            <div className="lg:col-start-1 lg:row-start-1">
              <aside>
                <h1 className="font-bold text-black text-4xl leading-none">Lets start a project</h1>
                <section className="pt-4 pb-12 mb-8 border-b-hairline">
                  <p>We love talking about websites and web technology. Even if you just want to find out
                    about the technology we're using, feel free to drop us a message.
                  </p>
                </section>
                <section className="py-8 mb-4 w-4/5">
                  <h3 className="mb-2">DUDOS Online Ltd</h3>
                  <p>Established 2012 DUDOS Online Ltd one of the few web design companies in the 
                    UK that specialises in Front-end web design and full stack Javascript development.
                  </p>
                </section>
                <section className="py-8 mb-8 w-2/3">
                  <span>Phone us</span>
                  <a className="text-2xl block mb-4" href="tel:+442039209079">+44 (0)20 3920 9079</a>
                  <h3>Our opening hours</h3>
                  <ul className="list-none px-0">
                    <li>Monday - Friday</li>
                    <li>08:00 - 17:00 </li>
                  </ul>
                </section>
              </aside>
            </div>
          </section>
        </main>
      </>
    </Layout>
  )
}

export default ContactPage;
export const query = graphql`
{
  prismicContact {
    data {
      meta_description {
        text
      }
      meta_title {
        text
      }
    }
  }
}
`
