import React from 'react';

const SelectBox = ({
    name,
    label = "", 
    options = [], 
    placeholder = "Please select an option",
    required = false
  }) => {
  return (
    <div className="mb-8">
      <label className="block">
        <div className="flex">
          <strong className="flex-1 inline-block font-bold mb-2">{label}</strong>

          {!required &&
            <span className="inline-block text-gray-500 italic">optional</span>
          }
        </div>
        <div className="relative">
          <div className="absolute bg-primary w-1 h-full rounded-tl-md"/>
          <select 
            name={name}
            id={name}
            placeholder="select" 
            className="form-select block bg-white w-full py-2 px-3 outline-none border-hairline shadow-md">
            <option disabled selected hidden>{placeholder}</option>
            {options.map((option) => {
              return <option>{option}</option>
            })}
          </select>
        </div>
        
      </label>
    </div>
  );
};

export default SelectBox;