import React from 'react';

const TextArea = ({name, label, required = false, placeholder = "Enter some long form content."}) => {
  return (
    <div className="mb-8">
      <label className="block">
        <div className="flex">
          <strong className="flex-1 inline-block font-bold mb-2">{label}</strong>

          {!required &&
            <span className="inline-block text-gray-500 italic">optional</span>
          }
        </div>
        <div className="relative border">
          <div className="bg-primary absolute w-1 h-full"/>
          <textarea 
            name={name}
            id={name}
            className="form-textarea block w-full" 
            rows="3" 
            placeholder={placeholder}>
          </textarea>
        </div>
      </label>
    </div>
  );
};

export default TextArea;